import { ref, reactive } from 'vue'

import { resourcesService } from '@/services'

export const useResources = () => {
  const resources = ref([])

  const resourceModel = reactive({
    name: null,
    domain: null,
    titleField: null,
    thumbnailUrlField: null,
    contentField: null,
    guidField: null
  })

  const setResorceModel = (data) => {
    resourceModel.name = data.name
    resourceModel.domain = data.domain
    resourceModel.titleField = data.titleField
    resourceModel.thumbnailUrlField = data.thumbnailUrlField
    resourceModel.contentField = data.contentField
    resourceModel.guidField = data.guidField
  }

  const getResources = ({ page = 1, size = 10 }) => {
    return resourcesService.getList({ size, page })
      .then(res => {
        resources.value = res.resources

        return res
      })
  }

  const getResource = (id) => {
    return resourcesService.getResource(id)
      .then(res => {
        setResorceModel(res)
      })
  }

  const createResource = () => {
    return resourcesService.createResource(resourceModel)
  }

  const editResource = (id) => {
    return resourcesService.editResource(id, resourceModel)
  }

  const deleteResource = (id) => {
    return resourcesService.deleteResource(id)
  }

  return {
    resources,
    resourceModel,
    setResorceModel,
    getResources,
    getResource,
    createResource,
    editResource,
    deleteResource
  }
}
