<template>
  <div class="relative">
    <div class="inline-flex items-center cursor-pointer">
      <div class="relative h-16 w-16 group">
        <input
          :id="id"
          type="checkbox"
          :checked="checked"
          class="h-full w-full opacity-0 cursor-pointer"
          @input="changeValue"
          @focus="isFocused = true"
          @blur="isFocused = false"
        >

        <div
          class="
            absolute inset-0 w-full h-full
            border pointer-events-none rounded-2
            flex items-center justify-center
            transition duration-200
          "
          :class="{
            'bg-blue-600 border-blue-600': checked,
            'border-blue-200 bg-blueGray-50': !checked,
            'border-red-500': validationErrorMessage,
            'ring-2 ring-offset-2 ring-indigo-500': isFocused
          }"
        >
          <i class="icon-checkmark text-white icon-size" />
        </div>
      </div>
      <label
        v-if="label"
        :for="id"
        class="ml-8 text-14 font-medium text-gray-900 cursor-pointer"
      >
        {{ label }}
      </label>
    </div>

    <p v-if="validationErrorMessage" class="w-full absolute text-14 left-0 top-0 text-red-500 truncate mt-20">
      {{ validationErrorMessage }}
    </p>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  name: 'AppCheckbox',

  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    modelValue: {
      type: [Array, Boolean]
    }
  },

  emits: ['update:modelValue'],

  setup (props, { emit, attrs }) {
    const isFocused = ref(false)
    const isArrayModel = Array.isArray(props.modelValue)

    const {
      // handleChange,
      errorMessage: validationErrorMessage
    } = useField(attrs.name, undefined,
      isArrayModel
        ? {
            initialValue: props.modelValue
          }
        : {
            type: 'checkbox',
            checkedValue: props.modelValue
          }
    )

    const checked = computed(() => {
      return isArrayModel ? props.modelValue.includes(props.value) : props.modelValue
    })

    watch(() => props.modelValue, (modelValue) => {
      // handleChange(modelValue)
    })

    const changeValue = (e) => {
      if (isArrayModel) {
        const modelCopy = new Set(props.modelValue)

        e.target.checked ? modelCopy.add(props.value) : modelCopy.delete(props.value)

        emit('update:modelValue', [...modelCopy])
      } else {
        emit('update:modelValue', e.target.checked)
      }
    }

    return {
      isFocused,
      validationErrorMessage,
      checked,
      changeValue
    }
  }
})
</script>

<style lang="scss" scoped>
  .icon-size {
    font-size: 10px;
  }
</style>
