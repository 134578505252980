import { ref } from 'vue'

import { platformsService } from '@/services'

export const usePlatforms = () => {
  // Variables
  const platforms = ref([])

  const getPlatforms = () => {
    return platformsService.getAll()
      .then(res => {
        platforms.value = res.platforms

        return res.platforms
      })
  }

  const updatePlatforms = () => {
    const udpatePromises = platforms.value.map(p => {
      return platformsService.update(p.platform, p.active)
    })

    return Promise.all(udpatePromises)
      .then(() => {
        return getPlatforms()
      })
  }

  return {
    platforms,
    getPlatforms,
    updatePlatforms
  }
}
