import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const usePagination = (paginationKey) => {
  const paginationKeyName = `${paginationKey}_paginationKey`
  const cachedData = localStorage.getItem(paginationKeyName)

  const router = useRouter()
  const route = useRoute()

  const total = ref(0)
  const size = cachedData?.size ? ref(cachedData.size) : ref(10)
  const page = cachedData?.page ? ref(cachedData.page) : ref(1)

  const setPaginationFromQuery = () => {
    const pageQuery = route.query.page
    if (pageQuery) {
      page.value = parseInt(pageQuery)
    }

    const sizeQuery = route.query.size
    if (pageQuery) {
      size.value = parseInt(sizeQuery)
    }

    localStorage.setItem(paginationKeyName, { page: page.value, size: size.value })
  }

  const onUpdatePage = (newPage) => {
    page.value = newPage

    const routeName = route.name
    const routeQuery = {
      ...route.params,
      page: page.value,
      size: size.value
    }

    router.replace({ name: routeName, query: routeQuery })
    localStorage.setItem(paginationKeyName, { page: page.value, size: size.value })
  }

  const onUpdateTotal = (newTotal) => {
    total.value = newTotal
  }

  return {
    total,
    size,
    page,

    setPaginationFromQuery,
    onUpdatePage,
    onUpdateTotal
  }
}
