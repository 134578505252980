import { ref, reactive } from 'vue'

import { postsService } from '@/services'

import { generateTelegramDMO, generateInstagramDMO, generateTwitterDMO } from './static/socialDMOLabels'

export const usePosts = () => {
  const posts = ref([])

  const postModel = reactive({
    title: null,
    thumbnailUrl: null,
    content: null,
    telegram: null,
    twitter: null,
    instagram: null
  })

  const setPostModel = (data) => {
    postModel.title = data.title
    postModel.thumbnailUrl = data.thumbnailUrl
    postModel.content = data.content
    postModel.telegram = data.telegram
    postModel.twitter = data.twitter
    postModel.instagram = data.instagram
  }

  const getPosts = ({ page = 1, size = 10, options }) => {
    return postsService.getList({ size, page, options })
      .then(res => {
        posts.value = res.posts

        return res
      })
  }

  const getPost = (id) => {
    return postsService.getPost(id)
      .then(res => {
        setPostModel(res)
      })
  }

  const publishPost = (id) => {
    return postsService.publishPost(id, postModel)
  }

  const createAndPublishPost = () => {
    return postsService.createAndPublishPost(postModel)
  }

  const editPost = (id) => {
    return postsService.editPost(id, postModel)
  }

  const deletePost = (id) => {
    return postsService.deletePost(id)
  }

  const generateDailyMarketOverviewPost = () => {
    return postsService.generateDailyMarketOverviewPost()
      .then(res => {
        const telegram = generateTelegramDMO(res.marketData)
        const instagram = generateInstagramDMO(res.marketData)
        const twitter = generateTwitterDMO(res.marketData)

        return {
          telegram,
          twitter,
          instagram
        }
      })
  }

  return {
    posts,
    postModel,
    setPostModel,
    getPosts,
    getPost,
    publishPost,
    createAndPublishPost,
    editPost,
    deletePost,
    generateDailyMarketOverviewPost
  }
}
