import { ref, reactive } from 'vue'

import { socialSignaturesService } from '@/services'

export const useSocialSignatures = () => {
  const socialSignatures = ref([])

  const socialSignatureModel = reactive({
    signature: null,
    isActive: null
  })

  const setSocialSignatureModel = (data) => {
    socialSignatureModel.signature = data.signature
    socialSignatureModel.isActive = data.isActive
  }

  const getSocialSignatures = ({ page = 1, size = 10 }) => {
    return socialSignaturesService.getList({ size, page })
      .then(res => {
        socialSignatures.value = res.signatures

        return res
      })
  }

  const getSocialSignature = (id) => {
    return socialSignaturesService.getSocialSignature(id)
      .then(res => {
        setSocialSignatureModel(res)
      })
  }

  const editSocialSignature = (id) => {
    return socialSignaturesService.editSocialSignature(id, socialSignatureModel)
  }

  return {
    socialSignatures,
    socialSignatureModel,
    setSocialSignatureModel,
    getSocialSignatures,
    getSocialSignature,
    editSocialSignature
  }
}
