import { reactive } from 'vue'

import { store } from '@/store'
import { profileService } from '@/services'

export const useProfile = () => {
  // Variables
  const profileModel = reactive({
    password: null,
    newPassword: null,
    confirmNewPassword: null,
    email: null,
    firstName: null,
    lastName: null
  })

  const setProfileModel = (data) => {
    profileModel.password = data.password || null
    profileModel.newPassword = data.newPassword || null
    profileModel.confirmNewPassword = data.confirmNewPassword || null
    profileModel.email = data.email || null
    profileModel.firstName = data.firstName || null
    profileModel.lastName = data.lastName || null
  }

  const changePassword = () => {
    return profileService.changePassword(profileModel)
  }

  const editProfile = () => {
    return profileService.editProfile(profileModel)
      .then(async res => {
        await store.dispatch('authStore/getUser')

        return res
      })
  }

  return {
    profileModel,
    setProfileModel,
    changePassword,
    editProfile
  }
}
