import { converBigNumberToReadLabel } from '@/core/helpers'
import { localeNumber } from '@/core/localisation'

export const generateTelegramDMO = (data) => {
  const title = '​​<b>​​📈Daily Market Overview​​</b> \n\n'

  // eslint-disable-next-line
  const marketCapitalization = `Market capitalization: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.total_market_cap_yesterday)} (${data.coinmarketcapQuotes.quote.USD.total_market_cap_yesterday_percentage_change.toFixed(2)}%)</b>\n`
  // eslint-disable-next-line
  const totalVolume24h = `24h volume: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.total_volume_24h)}</b>\n\n`

  // eslint-disable-next-line
  const altcoinMarketCap = `Altcoins market capitalization: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.altcoin_market_cap)}</b>\n`
  // eslint-disable-next-line
  const altcoinVolume24h = `Altcoins 24h volume: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.altcoin_volume_24h)}</b>\n\n`

  // eslint-disable-next-line
  const stablecoinMarketCap = `Stablecoins market capitalization: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.stablecoin_market_cap)}</b>\n`
  // eslint-disable-next-line
  const stablecoinVolume24h = `Stablecoins 24h volume: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.stablecoin_volume_24h)}</b>\n\n`

  // eslint-disable-next-line
  const defiMarketCap = `DeFi market capitalization: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.defi_market_cap)}</b>\n`
  // eslint-disable-next-line
  const defiVolume24h = `DeFi 24h volume: <b>${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.defi_volume_24h)}</b>\n\n`

  // eslint-disable-next-line
  const btcDominance = `The BTC dominance: <b>${data.coinmarketcapQuotes.btc_dominance.toFixed(2)}%</b>\n`
  // eslint-disable-next-line
  const ethDominance = `The ETH dominance: <b>${data.coinmarketcapQuotes.eth_dominance.toFixed(2)}%</b>\n\n`

  // eslint-disable-next-line
  const fearIndex = `Fear & Greed Index: <b>${data.fearIndex.data[0].value} (${data.fearIndex.data[0].value_classification})</b>\n\n`

  // eslint-disable-next-line
  const activeCryptocurrencies = `Active cryptocurrencies: <b>${localeNumber(data.coinmarketcapQuotes.active_cryptocurrencies)}</b>\n`
  // eslint-disable-next-line
  const activeExchanges = `Active exchanges: <b>${localeNumber(data.coinmarketcapQuotes.active_exchanges)}</b>\n`
  // eslint-disable-next-line
  const activeMarketPairs = `Active market pairs: <b>${localeNumber(data.coinmarketcapQuotes.active_market_pairs)}</b>\n\n`

  let topGainersStr = '​​<b>​​📈 Top Gainers​​</b> \n'
  data.coinmarketcapTopGainers
    .splice(0, 5)
    .forEach(item => {
      topGainersStr += `${item.name} (${item.symbol}) <b>+${item.quote.USD.percent_change_24h.toFixed(2)}%</b> \n`
    })

  let topLosersStr = '​\n​<b>📉 Top Losers​</b> \n'
  data.coinmarketcapTopLosers
    .splice(0, 5)
    .forEach(item => {
      topLosersStr += `${item.name} (${item.symbol}) <b>${item.quote.USD.percent_change_24h.toFixed(2)}%</b> \n`
    })

  return title +
    marketCapitalization +
    totalVolume24h +
    altcoinMarketCap +
    altcoinVolume24h +
    stablecoinMarketCap +
    stablecoinVolume24h +
    defiMarketCap +
    defiVolume24h +
    btcDominance +
    ethDominance +
    fearIndex +
    activeCryptocurrencies +
    activeExchanges +
    activeMarketPairs +
    topGainersStr +
    topLosersStr + '\n'
}

export const generateInstagramDMO = (data) => {
  const title = '​​📈Daily Market Overview​​ \n\n'

  // eslint-disable-next-line
  const marketCapitalization = `Market capitalization: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.total_market_cap_yesterday)} (${data.coinmarketcapQuotes.quote.USD.total_market_cap_yesterday_percentage_change.toFixed(2)}%)\n`
  // eslint-disable-next-line
  const totalVolume24h = `24h volume: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.total_volume_24h)}\n\n`

  // eslint-disable-next-line
  const altcoinMarketCap = `Altcoins market capitalization: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.altcoin_market_cap)}\n`
  // eslint-disable-next-line
  const altcoinVolume24h = `Altcoins 24h volume: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.altcoin_volume_24h)}\n\n`

  // eslint-disable-next-line
  const stablecoinMarketCap = `Stablecoins market capitalization: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.stablecoin_market_cap)}\n`
  // eslint-disable-next-line
  const stablecoinVolume24h = `Stablecoins 24h volume: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.stablecoin_volume_24h)}\n\n`

  // eslint-disable-next-line
  const defiMarketCap = `DeFi market capitalization: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.defi_market_cap)}\n`
  // eslint-disable-next-line
  const defiVolume24h = `DeFi 24h volume: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.defi_volume_24h)}\n\n`

  // eslint-disable-next-line
  const btcDominance = `The BTC dominance: ${data.coinmarketcapQuotes.btc_dominance.toFixed(2)}%\n`
  // eslint-disable-next-line
  const ethDominance = `The ETH dominance: ${data.coinmarketcapQuotes.eth_dominance.toFixed(2)}%\n\n`

  // eslint-disable-next-line
  const fearIndex = `Fear and Greed Index: ${data.fearIndex.data[0].value} (${data.fearIndex.data[0].value_classification})\n\n`

  // eslint-disable-next-line
  const activeCryptocurrencies = `Active cryptocurrencies: ${localeNumber(data.coinmarketcapQuotes.active_cryptocurrencies)}\n`
  // eslint-disable-next-line
  const activeExchanges = `Active exchanges: ${localeNumber(data.coinmarketcapQuotes.active_exchanges)}\n`
  // eslint-disable-next-line
  const activeMarketPairs = `Active market pairs: ${localeNumber(data.coinmarketcapQuotes.active_market_pairs)}\n\n`

  let topGainersStr = '​​📈 Top Gainers​​ \n'
  data.coinmarketcapTopGainers
    .splice(0, 5)
    .forEach(item => {
      topGainersStr += `${item.name} (${item.symbol}) +${item.quote.USD.percent_change_24h.toFixed(2)}% \n`
    })

  let topLosersStr = '​\n​📉 Top Losers​ \n'
  data.coinmarketcapTopLosers
    .splice(0, 5)
    .forEach(item => {
      topLosersStr += `${item.name} (${item.symbol}) ${item.quote.USD.percent_change_24h.toFixed(2)}% \n`
    })

  return title +
    marketCapitalization +
    totalVolume24h +
    altcoinMarketCap +
    altcoinVolume24h +
    stablecoinMarketCap +
    stablecoinVolume24h +
    defiMarketCap +
    defiVolume24h +
    btcDominance +
    ethDominance +
    fearIndex +
    activeCryptocurrencies +
    activeExchanges +
    activeMarketPairs +
    topGainersStr +
    topLosersStr + '\n'
}

export const generateTwitterDMO = (data) => {
  const title = '​​📈Daily Market Overview​​ \n\n'

  // eslint-disable-next-line
  const marketCapitalization = `Market capitalization: ${converBigNumberToReadLabel(data.coinmarketcapQuotes.quote.USD.total_market_cap_yesterday)} (${data.coinmarketcapQuotes.quote.USD.total_market_cap_yesterday_percentage_change.toFixed(2)}%)\n\n`

  // eslint-disable-next-line
  const btcDominance = `The BTC dominance: ${data.coinmarketcapQuotes.btc_dominance.toFixed(2)}%\n`
  // eslint-disable-next-line
  const ethDominance = `The ETH dominance: ${data.coinmarketcapQuotes.eth_dominance.toFixed(2)}%\n\n`

  // eslint-disable-next-line
  const fearIndex = `Fear & Greed Index: ${data.fearIndex.data[0].value} (${data.fearIndex.data[0].value_classification})\n\n`

  return title +
    marketCapitalization +
    btcDominance +
    ethDominance +
    fearIndex
}
